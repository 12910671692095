import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import { Container, Row, Col, Hidden, Visible } from "react-grid-system"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import * as styles from "./blog.module.scss"

const BlogList = props => {
  const [layout, setLayout] = useState({ cols: 4, chunksize: 3 })
  const { blogposts } = props.data
  const rows = []

  useEffect(() => {
    const w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )

    if (w < 992 && layout.chunksize !== 2) {
      setLayout({ chunksize: 2, cols: 6 })
    } else if (layout.chunksize !== 3) {
      setLayout({ chunksize: 3, cols: 4 })
    }
  }, [layout.chunksize])

  for (let i = 0; i < blogposts.edges.length; i += layout.chunksize) {
    rows.push(blogposts.edges.slice(i, i + layout.chunksize))
  }

  return (
    <Layout location="/blog" style={{ backgroundColor: "#f6f6f8" }}>
      <SEO title="Blog" />
      <Container>
        <h1 style={{ marginTop: "3em" }}>Blog</h1>
        <div className={styles.root}>
          <Hidden xs sm>
            {rows.map((row, ridx) => (
              <Row key={`row-${ridx}`}>
                {row.map(post => {
                  return (
                    <Col md={layout.cols} key={`card-${post.node.fields.slug}`}>
                      <Link to={post.node.fields.slug}>
                        <div className={styles.card}>
                          <div className={styles.background}>
                            <Img
                              fluid={
                                post.node.frontmatter.featuredImage
                                  .childImageSharp.fluid
                              }
                            />
                          </div>
                          <div className={styles.contentWrapper}>
                            <div className={styles.content}>
                              <h4>{post.node.frontmatter.title}</h4>
                              <p>{post.node.excerpt}</p>
                              <p className={styles.link}>Czytaj dalej...</p>
                            </div>
                            <svg
                              height="60px"
                              width="100%"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="none"
                            >
                              <polygon
                                points="0,0 100,0 0,100"
                                style={{
                                  fill: "#fff",
                                  strokeWidth: 0,
                                }}
                              />
                            </svg>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  )
                })}
              </Row>
            ))}
          </Hidden>
          <Visible xs sm>
            {rows.map((row, ridx) => (
              <Row key={`row-${ridx}`}>
                {row.map(post => {
                  return (
                    <Col xs={12} key={`card-${post.node.fields.slug}`}>
                      <Link to={post.node.fields.slug}>
                        <div className={styles.card}>
                          <div className={styles.content}>
                            <h4>{post.node.frontmatter.title}</h4>
                            <p>{post.node.excerpt}</p>
                            <p className={styles.link}>Czytaj dalej...</p>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  )
                })}
              </Row>
            ))}
          </Visible>
        </div>
      </Container>
    </Layout>
  )
}

export default BlogList
export const pageQuery = graphql`
  query blogposts {
    blogposts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//blog//" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(format: PLAIN, pruneLength: 150)
          frontmatter {
            title
            tags
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 350, maxHeight: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
